import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, } from "@mui/material";
import { getAdminlistParticipantsData } from "../service/api";
import { BASE_URL } from "../service/httpclient";
import { removeItem } from "../service/storage";
import CustomInput from "../component/inputfield";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import {
  DefaultAppBox,
  DefaultAppContainer,
  PrimaryButton,
  SecondaryButton,
  TopContainer,
} from "../component/common/element";

function AudioCell({ value }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);
  const [currentPlayingRef, setCurrentPlayingRef] = useState(null);

  const handlePlay = async () => {
    setIsPlaying(true);
    setCurrentPlayingRef(audioRef);
    await audioRef.current.play();
  };

  const handlePause = () => {
    setIsPlaying(false);
    setCurrentPlayingRef(null);
    audioRef.current.pause();
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  useEffect(() => {
    // Pause the previous audio if a new one is clicked
    if (currentPlayingRef && currentPlayingRef !== audioRef) {
      currentPlayingRef.current.pause();
      currentPlayingRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, [audioRef, currentPlayingRef]);

  return (
    <>
      <audio
        ref={audioRef}
        onEnded={handlePause}
        onTimeUpdate={handleTimeUpdate}
      >
        <source
          src={`${BASE_URL}/admin/streamAudio/${value}`}
          type="audio/mpeg"
        />
      </audio>
      {isPlaying ? (
        <button style={{
          background: 'transparent',
          borderRadius: '50%'
        }}
          onClick={handlePause}><PauseIcon /></button>
      ) : (
        <button style={{
          background: 'transparent',
          borderRadius: '50%'
        }}
          onClick={handlePlay}><PlayArrowIcon /></button>
      )}
      <span>  {formatTime(currentTime)}</span>
    </>
  );
}

function RecordingList() {
  const navigate = useNavigate();
  const [searchdata, setSearchdata] = useState(null);
  const [search, setSearch] = useState("");
  var bg = false;
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [userInfo, setUserInfo] = useState({
    isOpen: false,
    id: null,
  });

  const getData = async () => {
    const resp = await getAdminlistParticipantsData();
    if (resp.status === 401) {
      removeItem("auth");
      removeItem("access_token");
      removeItem("expiry");
      return navigate("/admin");
    }
    setData(resp.data.data);
    if (bg === false)
      setSearchdata(resp.data.data)
  };



  useEffect(() => {
    getData();
    console.log("trm", searchdata);
    // eslint-disable-next-line
  }, []);

  const rows = data?.map((row, index) => ({
    id: index + 1,
    sx: row.signers.map((m) => { return m.name }),
    files: row.files.map((m) => { return m }) || [],
    ...row,
  }));


  const findElementsWithOriginalName = async (jsonArray, originalName) => {
    const foundElements = [];
    bg = true;
    for (const obj of jsonArray) {
      for (const file of obj.files) {
        if (file.file_info.originalname.indexOf(originalName) > 1) {
          foundElements.push(file);
        }
      }
    }
    return foundElements;
  }

  const filterEnvelopes = (s) => {

    var d = data?.map((r, i) => {
      return r.files?.filter((f) => f.file_info?.originalname === s)
    }
    )
    d = d?.filter((y) => y !== undefined)
    console.log("d", d)
    return d;
  }
  const handleCellClick = (params) => {
    setUserInfo({
      isOpen: true,
      id: params.row.user_id,
    });
  };

  const columns = [
    {
      field: "files",
      headerName: "Files",
      hide: true,
      flex: 4,
      renderCell: (params) => {
        return (
          <div
            style={{ cursor: "pointer" }}
          >
            <ul className="flex1">
              {params.value.map((role, index) => (
                <li key={index}>
                  {role.file_info?.originalname}<span>{"  "}</span>
                  <AudioCell value={role?._id} />
                </li>
              ))}
            </ul>
          </div>
        );
      },
    },
  ];

  return (

    <TopContainer>
      <DefaultAppContainer>
        <DefaultAppBox>
          <Typography variant="h6" textAlign={"center"} sx={{ my: 5 }}>
            Recording List
          </Typography>
          <CustomInput
            changeEvent={(value) => {
              setSearch(value);
            }}
            value={search}
            placeholder={`Search Envelopes`}
          />
          <ul style={{ padding: "0px", display: "inline-block", width: "100%" }}>
            <li style={{ listStyle: "none", width: "45%", float: "left", background: "" }}>
              <PrimaryButton
                variant='outlined'
                size="large"
                onClick={async () => {
                  const x = await findElementsWithOriginalName(data, search);
                  setSearchdata(x);
                  console.log("xxxx", x);
                  console.log("data", data[0]);
                }}>
                Search
              </PrimaryButton>
            </li>
            <li style={{ listStyle: "none", width: "45%", float: "right" }}>

              <SecondaryButton
                variant='outlined'
                size="large"
                onClick={async () => {
                  const x = await findElementsWithOriginalName(data, search);
                  setSearchdata(data);
                  console.log("xxxx", x);
                  console.log("data", data[0]);
                }}>
                Clear
              </SecondaryButton>

            </li>

          </ul>

          <ul className="flex" style={{ margin: "0px auto", width: "100%", padding: "10px 0 20px 0", }}>
            {
              searchdata?.map((r, i) => (
                r.files?.map((f, x) => (
                  <li key={x} style={{ background: "rgb(90 190 43)", listStyle: "none", marginBottom: "8px", borderRadius: "6px", border: "1px solid", padding: "10px" }}>
                    {f.file_info?.originalname}<span>{"  "}</span>
                    <span style={{ float: "right",transform: 'translateY(-23%)' }}>
                      <AudioCell value={f?._id} />
                    </span>
                  </li>
                )) ||
                (
                  <li key={r} style={{ background: "rgb(90 190 43)", listStyle: "none", marginBottom: "8px", borderRadius: "6px", border: "1px solid", padding: "10px" }}>
                    {r.file_info?.originalname}<span>{"  "}</span>
                    <span style={{ float: "right", transform: 'translateY(-23%)' }}>
                      <AudioCell value={r?._id} />
                    </span>
                  </li>
                )
              ))
            }
          </ul>

          <SecondaryButton
            variant='outlined'
            size="large"
            onClick={() => {
              navigate("/mainpage");
            }}>
            Go Back
          </SecondaryButton>

        </DefaultAppBox>
      </DefaultAppContainer>
    </TopContainer>

  );
}

export default RecordingList;
