import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DefaultAppBox,
  DefaultAppContainer,
  PrimaryButton,
  SecondaryButton,
  TopContainer,
} from "../component/common/element";
import CustomInput from "../component/inputfield";
import { createApplication } from "../service/api";
import { getUser } from "../service/auth";
import { setItem } from "../service/storage";

export default function CreateNewApplication() {
  const navigate = useNavigate();
  const params = useParams();
  const user = getUser();
  const [pageMode, setPageMode] = useState("create_application");
  const [participants, setParticipants] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [appname, setAppname] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState("");

  const goToLogin = () => {
    navigate("/login");
  };

  if (!user) {
    goToLogin();
  }
  if (!user.subscription && !params.user_id) {
    setTimeout(() => {
      navigate("/subscription");
    }, 500);
  }

  const handleCreateApplicationSubmit = async () => {
    const data = participants;
    const _participant = data.filter(
      (item) => item.name !== "" && item.signature !== ""
    );
    if (_participant.length > 0) {
      try {
        const response = await createApplication(_participant);
        console.log("createApplication =>", response);
        if (response.status === 200) {
          setSucessMessage("Application Created Successfully.");
          setItem("fileSession", response.data.data.session);
        } else {
          setErrorMessage("Something went Wrong..");
        }
        setPageMode("application_created");
      } catch (error) {
        console.log(error);
        if (error.response.data.message === "Require Access token")
          navigate("/consentsign");
      }
    }
  };

  const addParticipant = () => {
    const data = [...participants];
    data.push({appname, name, signature: email });
    setParticipants(data);
  };

  const removeParticipant = (index) => {
    console.log(index);

    const data = [...participants];
    console.log(index, participants, data);
    data.splice(index, 1);
    setParticipants(data);
  };

  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          <DefaultAppBox>
            <Typography
              variant="h4"
              textAlign={"center"}
              sx={{ fontSize: "28px", my: 3 }}>
              Consent Participant
            </Typography>
            {successMessage && (
              <Typography
                textAlign={"center"}
                sx={{
                  mb: 2,
                  fontSize: "14px",
                  borderRadius: "5px",
                  backgroundColor: "#7DE24E77",
                  p: "10px",
                }}
                className="text-white">
                {successMessage}
              </Typography>
            )}
            {errorMessage && (
              <Typography
                textAlign={"center"}
                sx={{
                  mb: 2,
                  fontSize: "14px",
                  borderRadius: "5px",
                  backgroundColor: "#FF78BB77",
                  p: "10px",
                }}
                className="text-white">
                {errorMessage}
              </Typography>
            )}
            {pageMode === "create_application" && (
              <>
                <CustomInput
                  changeEvent={(value) => {
                    setAppname(value);
                  }}
                  value={appname}
                  placeholder={`Application Name`}
                />
                <CustomInput
                  changeEvent={(value) => {
                    setName(value);
                  }}
                  value={name}
                  placeholder={`Participant Name`}
                />
                <CustomInput
                  changeEvent={(value) => {
                    setEmail(value);
                  }}
                  value={email}
                  placeholder={`Participant Email`}
                />
              </>
            )}
            {pageMode === "create_application" && (
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mt: 2,
                  width: "100%",
                }}>
                <PrimaryButton
                  variant='outlined'
                  size="large"
                  onClick={() => {
                    addParticipant();
                  }}>
                  Add Participant
                </PrimaryButton>

                {participants &&
                  participants.length > 0 &&
                  participants.map((participant, participantIndex) => {
                    return (
                      <Box
                        key={participantIndex}
                        sx={{
                          borderRadius: 1,
                          padding: "10px",
                          backgroundColor: "#7DE24E77",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "95%",
                        }}>
                        <Typography
                          textAlign={"left"}
                          fontWeight={"600"}
                          sx={{ color: "#fff", fontSize: "14px" }}>
                          App: {participant.appname} <br />
                          Name: {participant.name} <br />
                          Email: {participant.signature}
                        </Typography>
                        <PrimaryButton
                          variant='outlined'
                          size="small"
                          sx={{width: 'auto'}}
                          onClick={() => removeParticipant(participantIndex)}>
                          X
                        </PrimaryButton>
                      </Box>
                    );
                  })}

                {participants && participants.length > 0 && (
                  <PrimaryButton
                    variant='outlined'
                    size="large"
                    onClick={() => handleCreateApplicationSubmit()}>
                    Submit
                  </PrimaryButton>
                )}
              </Stack>
            )}
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mt: 2,
                width: "100%",
              }}>
              <SecondaryButton
                variant='outlined'
                size="large"
                onClick={() => {
                  navigate("/applications");
                }}>
                Go Back
              </SecondaryButton>
            </Stack>
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
}
