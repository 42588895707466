import React from "react";
import { Navigate } from "react-router-dom";
import { getItem, removeItem } from "../../service/storage";
import Layout from "../Layout";
import { getUser } from "../../service/auth";

const withAdminAuthentication = (Component) => (props) => {
  let user = getUser();
  const token = getItem("access_token");
  const expiry = parseInt(getItem("expiry") ?? 0);
  console.log("Expiry: " , new Date(expiry));
  if (token && expiry >= new Date().getTime() && user && user.is_admin) {
    return (
      <Layout>
        <Component {...props} />;
      </Layout>
    );
  }

  removeItem("auth");
  removeItem("access_token");
  removeItem("expiry");

  return <Navigate replace to="/admin" />;
};

export default withAdminAuthentication;
