export const setItem = (key, value) => {
    localStorage.setItem(key.toString(), JSON.stringify(value));
}

export const getItem = (key) => {
    let data = localStorage.getItem(key)
    if (data) data = JSON.parse(data);
    return data;
}

export const removeItem = (key) => {
     localStorage.removeItem(key)
}

export const getToken = (key) => {
    let data = localStorage.getItem(key)
    if (data) data = JSON.parse(data);
    return data.data.access_token;
}