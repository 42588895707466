import { Button, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultAppBox, DefaultAppContainer, TopContainer } from "../component/common/element";

import {
 saveTerms,
 getTerms 
} from "../service/api";


export default function AdminTerms() {
    const [termrec, setTermrec] = useState({termtext:'No terms yet',_id:'6457fb90f80009d5e64d8d3a'});
    const [tms,setTms] = useState('');

    
  const StyledButton = styled(Button)(({ theme, color = "primary" }) => ({
    ":hover": {
      color: "#fff",
      backgroundColor: "primary",
      borderColor: "primary",
    },
  }));
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate("/admin/users");
  };
  const save = async() => {
    let o = await saveTerms(termrec);
    console.log(o.data.data);
    o.data.data.success ? setTms('Saved'):setTms('Something went wrong:',JSON.stringify(o));
};

const getTermRecords = async()=>{
    try{
    const resp = await getTerms();
    console.log("resp",resp.data.terms.termtext);

    setTermrec({termtext:resp.data.terms.termtext,_id:resp.data.terms._id});
    }catch(e){
        console.log("something went wrong",e);
    }
}


useEffect(() => {
    /*
    setTermtext(`Lorem ipsum dolor sit amet et delectus accommodare his consul copiosae legendos at vix
    ad putent delectus delicata usu. Vidit dissentiet eos cu eum an brute copiosae
    hendrerit. Eos erant dolorum an. Per facer affert ut. Mei iisque mentitum moderatius
    cu. Sit munere facilis accusam eu dicat falli consulatu at vis. Te facilisis
    mnesarchum qui posse omnium mediocritatem est cu. Modus argumentum ne qui tation
    efficiendi in eos. Ei`);*/
    getTermRecords();


}, []);

  return (
    <>
        <div className="admterm">       
            
              <textarea name="postContent" rows={20} cols={100} value={termrec.termtext}
              onChange={(e)=>{setTermrec({
                termtext:e.target.value,
                _id:termrec._id
              
            })}}
              />
              <br/>  
              <button className="admbtn" onClick={() => {
                console.log(termrec);
                save()
              }}>SAVE</button>
              {tms!=='' &&
                <div className="admtms">{tms}</div>
              }
              
        </div>       

    </>
  );
}
