import { useMediaQuery, useTheme } from "@mui/material";

export const useScreenSize = () => {
  const theme = useTheme();

  return {
    xs: useMediaQuery(theme.breakpoints.down("xs")),
    sm: useMediaQuery(theme.breakpoints.between("xs", "sm")),
    md: useMediaQuery(theme.breakpoints.between("sm", "md")),
    lg: useMediaQuery(theme.breakpoints.between("md", "lg")),
    xl: useMediaQuery(theme.breakpoints.between("lg", "xl")),
    xxl: useMediaQuery(theme.breakpoints.up("xl")),
  };
};
