import React from "react";
import { Navigate } from "react-router-dom";
import { getItem, removeItem } from "../../service/storage";

const withAuthentication = (Component) => (props) => {
  const token = getItem("access_token");
  const expiry = parseInt(getItem("expiry") ?? 0);

  if (token && expiry >= new Date().getTime()) {
    return <Component {...props} />;
  }

  removeItem("auth");
  removeItem("access_token");
  removeItem("expiry");

  return <Navigate replace to='/login' />;
};

export default withAuthentication;
