import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import withAdminAuthentication from "./component/withAuthentication/withAdminAuthentication";
import withAuthentication from "./component/withAuthentication/withAuthentication";
import AdminLogin from "./page/AdminLogin.jsx";
import AdminPayments from "./page/AdminPayments.jsx";
import AdminRecordings from "./page/AdminRecordings.jsx";
import AdminSubscriptions from "./page/AdminSubscriptions.jsx";
import AdminUsers from "./page/AdminUsers.jsx";
import Audio from "./page/Audio";
import CallBack from "./page/CallBack";
import ConsentSign from "./page/ConsentSign";
import CreateNewApplication from "./page/CreateNewApplication";
import ErrorPage from "./page/Error";
import ListOfApplications from "./page/ListOfApplications";
import Login from "./page/Login";
import MainPage from "./page/MainPage";
import PayemntLandingPage from "./page/PaymentLandingPage";
import Recording from "./page/Recording";
import RecordingSubmit from "./page/RecordingSubmit";
import SignUp from "./page/Signup";
import Submission from "./page/Submission";
import Subscription from "./page/Subscription";
import Terms from "./page/Terms";
import VerificationPage from "./page/VerificationPage";
import RecordingList from "./page/RecordingList";
import AdminTerms from "./page/AdminTerms";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/mainpage" Component={withAuthentication(MainPage)} />
          <Route
            path="/applications"
            Component={withAuthentication(ListOfApplications)}
          />
          <Route
            path="/applications/create"
            Component={withAuthentication(CreateNewApplication)}
          />
          <Route
            path="/submission"
            Component={withAuthentication(Submission)}
          />
          <Route path="/recording" Component={withAuthentication(Recording)} />
          <Route
            path="/consentsign"
            Component={withAuthentication(ConsentSign)}
          />
          <Route
            path="/payment/:user_id"
            Component={withAuthentication(PayemntLandingPage)}
          />
          <Route
            path="/recordingsubmit"
            Component={withAuthentication(RecordingSubmit)}
          />
          <Route
            path="/recordingsubmit/:session"
            Component={withAuthentication(RecordingSubmit)}
          />
          <Route path="/audio/:id" element={<Audio />} />


          <Route path="/callback" Component={withAuthentication(CallBack)} />
          <Route
            path="/subscription"
            Component={withAuthentication(Subscription)}
          />
          <Route
            path="/verification/:user_id/:token"
            element={<VerificationPage />}
          />
          <Route path="/admin" element={<AdminLogin />} />

          <Route
            path="/admin/users"
            Component={withAdminAuthentication(AdminUsers)}
          />
          <Route
            path="/admin/payments"
            Component={withAdminAuthentication(AdminPayments)}
          />
          <Route
            path="/admin/subscriptions"
            Component={withAdminAuthentication(AdminSubscriptions)}
          />
          <Route
            path="/admin/envelopes"
            Component={withAdminAuthentication(AdminRecordings)}
          />
         
          
          <Route
            path="/admin/terms"
            Component={withAdminAuthentication(AdminTerms)}
          />


          <Route
            path="/recordinglist"
            Component={withAuthentication(RecordingList)}
          />
         
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
