import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DefaultAppBox, DefaultAppContainer, PrimaryButton, SecondaryButton, TopContainer } from "../component/common/element";
import CustomInput from "../component/inputfield";
import { changePassword, verifyUser } from "../service/auth";

export default function VerificationPage() {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [pageState, setPageState] = useState("verifying");
  const [mode, setMode] = useState(searchParams.get("mode") ?? "");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState("");

  useEffect(() => {
    if (params.user_id && params.token) {
      verifyUser({ id: params.user_id, token: params.token }).then((response) => {
        console.log("verifyUser", response);

        if (response.status === 200) {
          setErrorMessage("");
          setSucessMessage("We have successfully verified your email. Please login and proceed.");
          setPageState("verified");
        } else {
          setErrorMessage(
            "Something went wrong. We are not able to verify your email. Please go to login page and try changing your password."
          );
          setSucessMessage("");
          setPageState("unverified");
        }
      });
    }
  }, [params]);

  const goToLogin = () => {
    navigate("/login");
  };

  const handleSubmit = async () => {
    setErrorMessage("");
    setSucessMessage("");
    let errMessage = "",
      sucssMessage = "";

    if (password !== "" && password === cPassword) {
      const response = await changePassword({ id: params.user_id, password });

      console.log(response);

      if (response.status === 200) {
        setMode("forgot-password-success");
        sucssMessage = "Password Changed Successfully";
      } else {
        errMessage = response.data.message;
      }
    } else {
      errMessage = "Password and Confirm Password should be same";
    }
    setSucessMessage(sucssMessage);
    setErrorMessage(errMessage);
  };

  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          <DefaultAppBox>
            <Typography variant='h5' textAlign={"center"} sx={{ my: 5 }}>
              Welcome to Hedon XX
            </Typography>
            {pageState === "verifying" && (
              <Typography variant='h6' textAlign={"center"} sx={{ my: 5 }}>
                Please wait. We are verifying the details ...
              </Typography>
            )}
            {errorMessage && (
              <>
                <Typography
                  textAlign={"center"}
                  sx={{
                    mt: 2,
                    mb: 2,
                    fontSize: "14px",
                    borderRadius: 1,
                    padding: "20px",
                    color: "#fff",
                    backgroundColor: "#FF78BB77",
                    p: "10px",
                  }}
                  className='text-white'>
                  {errorMessage}
                </Typography>
              </>
            )}
            {successMessage && (
              <Typography
                textAlign={"center"}
                sx={{
                  mt: 2,
                  mb: 2,
                  fontSize: "14px",
                  borderRadius: 1,
                  padding: "20px",
                  color: "#fff",
                  backgroundColor: "#7DE24E77",
                  p: "10px",
                }}
                className='text-white'>
                {successMessage}
              </Typography>
            )}
            {pageState === "verified" && mode === "forgotpassword" && (
              <>
                <CustomInput
                  type='password'
                  changeEvent={setPassword}
                  value={password}
                  placeholder='Password'
                />
                <CustomInput
                  type='password'
                  changeEvent={setCPassword}
                  value={cPassword}
                  placeholder='Confirm Password'
                />
                <Box textAlign={"center"} sx={{ my: 5 }}>
                  <PrimaryButton
                    variant='outlined'
                    onClick={() => handleSubmit()}
                    size='large'>
                    Change Password
                  </PrimaryButton>
                </Box>
              </>
            )}
            {(pageState === "unverified" || pageState === "verified") && (
              <Box textAlign={"center"} sx={{ my: 5 }}>
                <SecondaryButton
                  variant='outlined'
                  onClick={() => goToLogin()}
                  size='large'>
                  Go To Login
                </SecondaryButton>
              </Box>
            )}
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
}
