import axios from "axios";

export const BASE_URL =
  process.env.REACT_APP_API_HOST || "https://backend.hedonxx.com";
axios.defaults.baseURL = BASE_URL;

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  withCredentials: true,
  headers: {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  },
});
