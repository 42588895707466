import React, { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Box, Stack, Typography } from "@mui/material";
import {
  DefaultAppBox,
  DefaultAppContainer,
  PrimaryButton,
  TopContainer,
} from "../component/common/element";
import { generateDocuSignAppAccessToken } from "../service/api";

export default function Audio() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const goToListing = useCallback(() => {
    navigate("/applications");
  }, [navigate]);

  const initialApiTrigger = useCallback(async () => {
    if (searchParams.get("code")) {
      const params = {
        docusign_consent_access_token: searchParams.get("code"),
        state: searchParams.get("state"),
      };
      const result = await generateDocuSignAppAccessToken(params);

      if (result.status === 200) {
        goToListing();
      }
    }
  }, [searchParams, goToListing]);

  useEffect(() => {
    initialApiTrigger();
  }, [initialApiTrigger]);

  const goToLogin = () => {
    navigate("/mainpage");
  };

  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          <DefaultAppBox>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h6" textAlign={"center"} sx={{ my: 6 }}>
                Please wait.... Redirecting to Listing Page
              </Typography>
            </Box>
            <Stack sx={{ gap: 4, mt: 3 }}>
              <PrimaryButton
                variant='outlined'
                onClick={() => goToLogin()}
                size="large">
                Return to Main Page
              </PrimaryButton>
            </Stack>
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
}
