import React from "react";
import {  Button, Dialog, DialogActions, DialogContent,DialogTitle, DialogContentText } from "@mui/material";


function ConfirmedModal({ confirmation, open }) {
  const handleClose = (unsubscribe) => {
    confirmation({ isOpen: false  });
  }
  return (
    <>
      <Dialog onClose={handleClose} open={open}>

        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
          You're successfully unsubscribed!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose(false)}>Close</Button>
        </DialogActions>


      </Dialog>
    </>
  );
}

export default ConfirmedModal;
