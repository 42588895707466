import { Box, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DefaultAppBox,
  DefaultAppContainer,
  PrimaryButton,
  SecondaryButton,
  TopContainer,
} from "../component/common/element";
import { updateAccessToken } from "../service/api";
import { getItem } from "../service/storage";
const BASE_URL =
  process.env.REACT_APP_API_HOST || "https://backend.hedonxx.com/";

export default function Audio() {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const parseQuery = (queryString) => {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  };

  const query = parseQuery(hash.replace("#", "?"));
  const [id, setId] = useState(null);

  const getAudioById = useCallback(() => {
    if (getItem("audio_id")) setId(getItem("audio_id"));
    else setId(params.id);
  }, [params.id]);

  useEffect(() => {
    getAudioById();
    if (query.access_token) updateAccessToken(query);
  }, [query, getAudioById]);

  const goToLogin = () => {
    navigate("/mainpage");
  };

  const audioFile = `${BASE_URL}/file/${id}`;
  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          <DefaultAppBox>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h6" textAlign={"center"} sx={{ my: 6 }}>
                Play Audio Recording
              </Typography>
              {id ? (
                <audio controls preload="auto">
                  <source src={audioFile} type="audio/mpeg" />
                </audio>
              ) : (
                ""
              )}
            </Box>
            <Stack sx={{ gap: 4, mt: 3 }}>

              <PrimaryButton
                variant='outlined'
                size="large"
                onClick={() => {
                  navigate("/applications");
                }}>
                Go To Applications
              </PrimaryButton>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mt: 2,
                width: "100%",
              }}>
              <SecondaryButton
                variant='outlined'
                onClick={() => goToLogin()}
                size="large">
                Return to Main Page
              </SecondaryButton>
            </Stack>
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
}
