import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultAppBox, DefaultAppContainer, SecondaryButton, TopContainer } from "../component/common/element";
import {
  getTerms
} from "../service/api";

export default function Terms() {
  const [termrec, setTermrec] = useState({ termtext: '', _id: '6457fb90f80009d5e64d8d3a' });
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate("/mainpage");
  };

  const getTermRecords = async () => {
    try {
      const resp = await getTerms();
      console.log("resp", resp.data.terms.termtext);

      setTermrec({ termtext: resp.data.terms.termtext, _id: resp.data.terms._id });
    } catch (e) {
      console.log("something went wrong", e);
    }
  }

  useEffect(() => {
    getTermRecords();
  }, []);

  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          <DefaultAppBox>
            <Typography
              variant='h5'
              textAlign={"center"}
              fontWeight={"600"}
              sx={{ color: "#fff", mt: 3, mb: 2, fontSize: "22px" }}>
              Term of Service
            </Typography>

            <Typography
              sx={{
                my: 2,
                borderRadius: 1,
                border: 1,
                padding: "20px",
                color: "#fff",
                fontSize: "18px",
                lineHeight: "1.3",
                overflowY: "scroll",
                height: "50vh"
              }}>
              {termrec.termtext}
            </Typography>
          </DefaultAppBox>
          <DefaultAppBox>
            <SecondaryButton
              variant='outlined'
              onClick={() => goToLogin()}
              size='large'>
              Go Back
            </SecondaryButton>
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
}
