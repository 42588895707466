import { Box, Stack, Typography, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DefaultAppBox,
  DefaultAppContainer,
  PrimaryButton,
  SecondaryButton,
  TopContainer,
} from "../component/common/element";
import Confirmation from "../component/Confirmation";
import ConfirmedModal from "../component/ConfirmedModal";
import { getUser, logout } from "../service/auth";
import { UnSubscription } from "../service/api";


export default function MainPage() {
  const navigate = useNavigate();

  const [subscription, SetSubscription] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [confirmedModalOpen, setConfirmedOpen] = useState(false);
  const goToTerms = () => {
    navigate("/terms");
  };
  const goToEnvelopes = () => {
    navigate("/recordinglist");
  };
  const goToLogin = () => {
    navigate("/login");
  };
  const goToConsentSign = () => {
    navigate("/applications");

  };
  const goToSubscription = () => {
    navigate("/subscription");
  };
  const user = getUser();
  if (!user) {
    goToLogin();
  }
  useEffect(() => {
    if (user.subscription) {
      SetSubscription(true);
    }
  }, [user]);

  const goToConfirmation = () => {
    setOpen(true);
  }


  const confirmation = (data) => {
    setOpen(data.isOpen);
    if (data.unsubscribe) {
      UnSubscriptionConfirmation();
    }
  }

  const UnSubscriptionConfirmation = async () => {
    const data = await UnSubscription();
    if (data) {
      SetSubscription(false);
      confirmedModal()
    }
  }
  const confirmedModal = () => {
    setConfirmedOpen(true);
  }

  const actionConfirmed = (data) => {
    setConfirmedOpen(data.isOpen);
  }

  const handleLogout = async () => {
    await logout();
    goToLogin();
  };

  return (
    <>
      <TopContainer>
      <ConfirmedModal confirmation={actionConfirmed} open={confirmedModalOpen}/>
      <Confirmation confirmation={confirmation} open={isOpen}/>

        <DefaultAppContainer>
          <DefaultAppBox>
            <Typography variant="h5" textAlign={"center"} sx={{ my: 5 }}>
              Welcome to Hedon XX
            </Typography>
            <Typography variant="h6" textAlign={"center"} sx={{ my: 5 }}>
              Keep Private Things Private
            </Typography>
            <Box textAlign={"center"} sx={{ my: 5 }}>
              {subscription ? (
                <PrimaryButton
                  variant="outlined"
                  size="large"
                  onClick={() => goToConsentSign()}
                >
                  View Your Applications/Make Recordings
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  variant="outlined"
                  size="large"
                  onClick={() => goToSubscription()}
                >
                  View Subscription Plans
                </PrimaryButton>
              )}
            </Box>
            <Stack
              sx={{ display: "flex", alignItems: "center", gap: 4, mt: 3 }}>
              <SecondaryButton
                variant="outlined"
                onClick={() => goToEnvelopes()}
                size="large">
                Play Audio Files
              </SecondaryButton>
            </Stack>
            <Stack sx={{ display: "flex", alignItems: "center", gap: 4, mt: 3 }}>
              <SecondaryButton
                variant="outlined"
                onClick={() => handleLogout()}
                size="large">
                Log Out
              </SecondaryButton>
              {user.subscription_id && 
              <SecondaryButton
                variant="outlined"
                onClick={() => goToConfirmation()}
                size="large">
                Cancel Subscription
              </SecondaryButton>
              }
            
              <SecondaryButton
                variant="outlined"
                onClick={() => goToTerms()}
                size="large">
                Terms of Service
              </SecondaryButton>
            </Stack>
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
}
