import StarIcon from "@mui/icons-material/StarBorder";
import { Box, Button, Container, Grid, Modal, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Payment from "../component/common/Payment/Payment";
import { TopContainer } from "../component/common/element";
import { getSubscription } from "../service/api";
import { getUser } from "../service/auth";
import { setItem } from "../service/storage";
import { useScreenSize } from "../utils/utils";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  padding: "5%",
  background: "#eee",
  border: "1px solid #ccc",
  boxShadow: 24,
  p: 4,
};

function Subscription() {
  const { sm, md, lg } = useScreenSize();
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate("/login");
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [clientSecret, setClient] = useState("");
  const [open, setOpen] = useState(false);
  const user = getUser();
  if (!user) {
    goToLogin();
  }

  const [tiers, setTiers] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const data = await getSubscription();
    setTiers([...data.data.data]);
  };

  const openModal = (client_secret, subscription_id) => {
    setItem("tier_id", subscription_id);
    setClient(client_secret);
    setOpen(true);
  };
  return (
    <>
      <TopContainer>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Container
            component="main"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
            <Box
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexDirection: "row",
                width: "100%",
                flexWrap: "wrap",
                gap: "20px",
              }}>
              {tiers.map((tier) => (
                <Grid
                  item
                  key={tier.title}
                  sx={{
                    flexBasis: sm ? "100%" : md ? "45%" : lg ? "25%" : "22%",
                    height: "300px",
                  }}>
                  <Card
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}>
                    <CardHeader
                      title={tier.title}
                      subheader={tier.header}
                      titleTypographyProps={{ align: "center" }}
                      action={tier.title === "Pro" ? <StarIcon /> : null}
                      subheaderTypographyProps={{
                        align: "center",
                      }}
                      sx={{
                        width: "100%",
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? theme.palette.grey[200]
                            : theme.palette.grey[700],
                      }}
                    />
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "baseline",
                          mb: 2,
                        }}>
                        <Typography
                          component="h2"
                          variant="h3"
                          color="text.primary">
                          ${tier.amount}
                        </Typography>
                        <Typography
                          variant="h6"
                          color="text.secondary"></Typography>
                      </Box>
                    </CardContent>
                    <CardActions>
                      <Button
                        onClick={() => openModal(tier.client_secret, tier._id)}
                        fullWidth
                        variant={tier.buttonVariant ? "outlined" : "contained"}>
                        {"Subscribe"}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Box>
          </Container>
        </Grid>
      </TopContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Box style={style}>
          <Payment clientSecret={clientSecret} />
        </Box>
      </Modal>
    </>
  );
}

export default Subscription;
