import React from "react";
import {  Button, Dialog, DialogActions, DialogContent,DialogTitle, DialogContentText } from "@mui/material";


function Confirmation({ confirmation, open }) {
  const handleClose = (unsubscribe) => {
    confirmation({ isOpen: false, unsubscribe  });
  }
  return (
    <>
      <Dialog onClose={handleClose} open={open}>

        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel your subscription?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose(false)}>Cancel</Button>
          <Button onClick={()=>handleClose(true)}>Sure</Button>
        </DialogActions>


      </Dialog>
    </>
  );
}

export default Confirmation;
