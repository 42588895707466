import {
  changePassword as changePasswordApi,
  login as loginApi,
  logout as logoutApi,
  register as registerApi,
  sendVerificationLink as sendVerificationLinkApi,
  verifyUser as verifyUserApi,
} from "./api";
import { getItem, removeItem, setItem } from "./storage";

export const login = async ({ username, password }) => {
  const response = await loginApi({ username, password });

  console.log("login", response);

  if (response.status === 200) {
    const now = new Date();
    setItem("auth", response.data);
    setItem("access_token", response.data.data.access_token);
    setItem("expiry", now.getTime() + 1000 * 60 * 60);
  }
  return { data: response.data, status: response.status, message:response.message };


};

export const adminlogin = async ({ username, password }) => {
  const response = await loginApi({ username, password });
  if (response.status === 200 && response.data.data.is_admin) {
    const now = new Date();
    setItem("auth", response.data);
    setItem("access_token", response.data.data.access_token);
    setItem("expiry", now.getTime() + 1000 * 60 * 60);
  }
//  return { data: response.data, status: response.status };
  return { data: response.data, status: response.status, message:response.message };

};




export const getUser = () => {
  const data = getItem("auth");

  if (data && data.data) {
    return data.data;
  } else {
    return null;
  }
};

export const register = async ({ username, password, adult }) => {
  const response = await registerApi({ username, password, adult });

  return { data: response.data, status: response.status };
};

export const verifyUser = async ({ id, token }) => {
  const response = await verifyUserApi({ id, token });

  return { data: response.data, status: response.status };
};

export const logout = async () => {
  const response = await logoutApi();
  if (response.status === 200) {
  }

  removeItem("auth");
  removeItem("access_token");
  removeItem("expiry");

  return { data: response.data, status: response.status };
};

export const sendVerificationLink = async ({ username }) => {
  const response = await sendVerificationLinkApi({ username });
  return { data: response.data, status: response.status };
};

export const changePassword = async ({ id, password }) => {
  const response = await changePasswordApi({ id, password });
  return { data: response.data, status: response.status };
};
