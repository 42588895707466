import { Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  DefaultAppBox,
  DefaultAppContainer,
  TopContainer,
} from "../component/common/element";
import { addPayment } from "../service/api";
import { getUser } from "../service/auth";
import { getItem, setItem } from "../service/storage";

export default function PayemntLandingPage() {
  const navigate = useNavigate();
  const params = useParams();
  const user = getUser();
  const [searchParams] = useSearchParams();
  const goToLogin = () => {
    navigate("/login");
  };

  if (!user) {
    goToLogin();
  }
  if (!user.subscription && !params.user_id) {
    setTimeout(() => {
      navigate("/subscription");
    }, 500);
  }
  const submitPayment = async (data) => {
    const req = await addPayment(data);

    if (req.data) {
      if (req.data.data === "success") {
        setItem("auth", { data: { ...user, subscription: true } });
        navigate("/mainpage");
      }
    }
  };

  if (params.user_id) {
    const tier_id = getItem("tier_id");
    setTimeout(() => {
      submitPayment({
        payment_intent_client_secret: searchParams.get(
          "payment_intent_client_secret"
        ),
        user_id: params.user_id,
        subscription_id: tier_id,
      });
    }, 3000);
  }

  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          <DefaultAppBox>
            <Typography
              variant="h4"
              textAlign={"center"}
              sx={{ fontSize: "28px", my: 3 }}>
              Please wait... Redirecting to Main Page
            </Typography>
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
}
