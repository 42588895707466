import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Grid, Container } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getAdminPaymentsData } from "../service/api";
import UserDetail from "../component/UserDetail";
import { removeItem } from "../service/storage";

function AdminPayments() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [userInfo, setUserInfo] = useState({
    isOpen: false,
    id: null,
  });

  const getData = async () => {
    const resp = await getAdminPaymentsData();
    if (resp.status === 401) {
      removeItem("auth");
      removeItem("access_token");
      removeItem("expiry");
      return navigate("/admin");
    }
    setData(resp.data.data);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const rows = data.map((row, index) => ({
    id: index + 1,
    ...row,
  }));

  const handleCellClick = (params) => {
    setUserInfo({
      isOpen: true,
      id: params.row.user_id,
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      flex: 1
    },
    {
      field: "username",
      headerName: "User Email",
      hide: true,
      valueGetter: (params) => {
        return params.row?.user_id?.username;
      },
      renderCell: (params) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleCellClick(params)}
          >
            {params.row?.user_id?.username}
          </div>
        );
      },
      flex: 1
    },
    {
      field: "status",
      headerName: "Payment Status",
      hide: true,
      valueGetter: (params) => {
        return params.row?.status;
      },
      flex: 1
    },
    {
      field: "active",
      headerName: "Subscription Status",
      hide: true,
      valueGetter: (params) => {
        return params.row?.subscription_id?.active;
      },
      flex: 1
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      valueGetter: (params) => params.row?.subscription_id?.amount,
    },
    {
      field: "duration",
      headerName: "Subscription Duration",
      flex: 1,
      valueGetter: (params) => params.row?.subscription_id?.duration,
    },
    {
      field: "title",
      headerName: "Subscription Title",
      flex: 1,
      valueGetter: (params) => params.row?.subscription_id?.title,
    },
    {
      field: "datetime",
      headerName: "Date/Time",
      flex: 1,
      valueGetter: (params) => {
        const dtValue = params.row?.dt;
        const date = new Date(dtValue);
        return date.toLocaleString();
      },
    },
  ];

  return (
    <>
      <Container sx={{ display: "flex", height: "80vh" }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={11} sm={8} md={12}>
            <Typography variant="h5" textAlign={"center"} sx={{ my: 5 }}>
              {!userInfo.isOpen ? "Payments" : ""}
            </Typography>
            {data && !userInfo.isOpen > 0 && (
              <DataGrid
                slots={{ toolbar: GridToolbar }}
                rows={rows}
                columns={columns}
                getRowId={(row) => row._id}
                sx={{
                  backgroundColor: "white",
                  height: "70vh",
                }}
              />
            )}
            {userInfo.isOpen && (
              <UserDetail userData={userInfo.id} setUserInfo={setUserInfo} />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default AdminPayments;
