import {Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DefaultAppBox, DefaultAppContainer, PrimaryButton, TopContainer } from "../component/common/element";

export default function Recording() {
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate("/mainpage");
  };

  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          <DefaultAppBox>
            <Typography variant='h5' fontWeight={"600"} textAlign={"center"}>
              Event Recording will start after both parties have signed the signature.
            </Typography>
            <Stack sx={{ display: "flex", alignItems: "center", gap: 3, mt: 3, width: "100%" }}>
              <PrimaryButton
                variant='outlined'
                onClick={() => goToLogin()}
                fullWidth
                size='large'>
                Main Page
              </PrimaryButton>
            </Stack>
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
}
