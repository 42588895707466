import { Button, Container, Grid, Stack, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import CustomInput from "../component/inputfield";
import { adminlogin } from "../service/auth";

const StyledButton = styled(Button)(({ theme, color = "primary" }) => ({
  ":hover": {
    backgroundColor: "#1565c0",
  },
  color: "#fff",
  backgroundColor: "primary",
  borderColor: "#fff",
}));

const AdminLogin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const goToHome = () => {
    navigate("/admin/users");
  };

  const submit = async () => {
    setErrorMessage("");
    let errMessage = "";

    if (username !== "" && password !== "") {
      const response = await adminlogin({ username, password });
      if (response.status === 200) {
        if (response.data.data.is_verified) {
          goToHome();
        } else {
          errMessage = "Account Not Verified.";
        }
      } else {
        errMessage = response.message;
      }
    } else {
      errMessage = "Email and Password are Mandatory for Login";
    }
    setErrorMessage(errMessage);
  };

  return (
    <>
      <Container sx={{ display: "flex", height: "100vh" }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={{ xs: 2, sm: 0, md: 0 }}>
          <Grid item xs={11} sm={8} md={4}>
            <Typography
              sx={{ display: "flex", height: "10vh", justifyContent: "center" }}
              variant='h4'>
              HEDON XX
            </Typography>
            <Typography sx={{ color: "#fff", mb: 3 }} variant='h5' textAlign={"center"}>
              Login to Admin Dashboard
            </Typography>
            <CustomInput
              type='text'
              changeEvent={setUsername}
              value={username}
              placeholder='Username/Email'
            />
            <CustomInput
              type='password'
              changeEvent={setPassword}
              value={password}
              placeholder='Password'
            />
            {errorMessage && (
              <Typography
                textAlign={"center"}
                sx={{
                  mb: 2,
                  fontSize: "14px",
                  borderRadius: "5px",
                  backgroundColor: "rgba(255,120,187,0.6)",
                  p: "4px",
                }}
                className='text-white'>
                {errorMessage}
              </Typography>
            )}
            <Stack sx={{ display: "flex", alignItems: "center", gap: 3, mt: 3 }}>
              <StyledButton
                variant='outlined'
                sx={{
                  color: "#fff",
                  borderColor: "#7DE24E",
                  backgroundColor: "#7DE24E",
                  textTransform: "capitalize",
                  borderRadius: "20px",
                }}
                onClick={() => submit()}
                fullWidth
                size='large'>
                Admin Login
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AdminLogin;
