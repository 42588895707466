import { axiosInstance } from "./httpclient";
import { getToken } from "./storage";

export const login = async ({ username, password }) => {
  var response = null;
  try{
   response = await axiosInstance.post("/user/login", {
    username,
    password,
  })

  return response;
 }catch(error){
    response = {error:error,success:false,response,message:"Invalid credentials!",status:500}
    console.log("error",response)
    return response;
 }
  

};

export const logout = async () => {
  const token = getToken("auth");

  const response = await axiosInstance.post(
    "/user/logout",
    {},
    { headers: { "x-token": token } }
  );

  return response;
};

export const addPayment = async (data) => {
  const token = getToken("auth");

  const response = await axiosInstance.post("/subscription", data, {
    headers: { "x-token": token },
  });

  return response;
};

export const register = async ({ username, password, adult }) => {
  const response = await axiosInstance.post("/user/register", {
    username,
    password,
    adult,
  });

  return response;
};

export const getAudio = async (id) => {
  const response = await axiosInstance.get(`/file/${id}`);

  return response;
};

export const UnSubscription = async () => {
  const token = getToken("auth");

  const response = await axiosInstance.post("/subscription/cancel",{}, {
    headers: { "x-token": token },
  });

  return response;

}

export const getSubscription = async () => {
  const token = getToken("auth");

  const response = await axiosInstance.get("/subscription", {
    headers: { "x-token": token },
  });

  return response;
};

export const generateDocuSignAppAccessToken = async (obj) => {
  const token = getToken("auth");

  const response = await axiosInstance.post(
    "/file/generateNSaveDocuSignAccessToken",
    { ...obj },
    { headers: { "x-token": token } }
  );

  return response;
};

export const updateAccessToken = async (obj) => {
  const token = getToken("auth");

  const response = await axiosInstance.post(
    "/file/access_token",
    { ...obj },
    { headers: { "x-token": token } }
  );

  return response;
};

export const createApplication = async (data) => {
  const token = getToken("auth");
  const response = await axiosInstance.post("/file/createApplication", data, {
    headers: { "x-token": token },
  });

  return response;
};

export const getConsentUrl = async () => {
  const token = getToken("auth");

  const response = await axiosInstance.get("/file/consentUrl", {
    headers: { "x-token": token },
  });

  return response;
};

export const getListOfApplications = async () => {
  const token = getToken("auth");

  const response = await axiosInstance.get("/file/getListOfApplications", {
    headers: { "x-token": token },
  });

  return response;
};

export const upload = async (data, headers) => {
  const token = getToken("auth");

  const response = await axiosInstance.post("/file", data, {
    headers: { "x-token": token, "content-type": "multipart/form-data" },
  });

  return response;
};

export const verifyUser = async ({ id, token }) => {
  const response = await axiosInstance.get(`/user/verify/${id}/${token}`);

  return response;
};

export const getAdminPaymentsData = async (data, headers) => {
  const token = getToken("auth");

  const response = await axiosInstance.get("/admin/listPayments", {
    params: data,
    headers: { "x-token": token, "content-type": "multipart/form-data" },
  });

  return response;
};

export const getAdminUsersData = async (data, headers) => {
  const token = getToken("auth");

  const response = await axiosInstance.get("/admin/listUsers", {
    params: data,
    headers: { "x-token": token, "content-type": "multipart/form-data" },
  });

  return response;
};

export const getAdminSubscriptionsData = async (data, headers) => {
  const token = getToken("auth");
  const response = await axiosInstance.get("/admin/listSubscriptions", {
    params: data,
    headers: { "x-token": token, "content-type": "multipart/form-data" },
  });

  return response;
};

export const getAdminlistParticipantsData = async (data, headers) => {
  const token = getToken("auth");

  const response = await axiosInstance.get("/admin/listParticipants", {
    params: data,
    headers: { "x-token": token, "content-type": "multipart/form-data" },
  });

  return response;
};

export const logoutUser = async ({ id, token }) => {
  const response = await axiosInstance.get(`user/verify/${id}/${token}`);

  return response;
};

export const sendVerificationLink = async (data) => {
  const response = await axiosInstance.post("/user/sendVerificationLink", data);

  return response;
};

export const changePassword = async (data) => {
  const response = await axiosInstance.post("/user/changePassword", data);

  return response;
};

export const updateListUsers = async (data) => {
  const token = getToken("auth");
  let request = {};
  request.userId = data._id;
  request.attributes = data;

  const response = await axiosInstance.post("/admin/updateUser", request, {
    headers: { "x-token": token, "content-type": "application/json" },
  });

  return response;
};

export const deleteListUsers = async (userId) => {
  const token = getToken("auth");

  const response = await axiosInstance.post(
    "/admin/deleteUser",
    { userId },
    {
      headers: { "x-token": token, "content-type": "application/json" },
    }
  );
  return response;
};

export const deleteListSubscriptions = async (id) => {
  let subscriptionId = String(id);
  console.log(subscriptionId);
  const token = getToken("auth");

  const response = await axiosInstance.post(
    "/admin/deleteSubscription",
    { subscriptionId },
    {
      headers: { "x-token": token, "content-type": "application/json" },
    }
  );

  return response;
};

export const updateListSubscriptions = async (data) => {
  const token = getToken("auth");
  let request = {};
  request.subscriptionId = data._id;
  request.attributes = data;

  const response = await axiosInstance.post(
    "/admin/updateSubscription",
    request,
    {
      headers: { "x-token": token, "content-type": "application/json" },
    }
  );

  return response;
};


export const saveTerms = async (data) => {
  const token = getToken("auth");
  console.log("data",JSON.stringify(data));

  const response = await axiosInstance.post(
    "/admin/terms",
    JSON.stringify(data),
    {
      headers: { "x-token": token, "content-type": "application/json" },
    }
  );

  return response;
};



export const getTerms = async () => {
  const token = getToken("auth");

  const response = await axiosInstance.get(
    "/admin/terms",
    {
      headers: { "x-token": token, "content-type": "application/json" },
    }
  );

  return response;
};