import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Container,
  IconButton,
  Tooltip,
  TextField,
  Box,
  Stack,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteListUsers,
  updateListUsers,
  getAdminUsersData,
} from "../service/api";
import UserDetail from "../component/UserDetail";
import { removeItem } from "../service/storage";

function AdminUsers() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedRow, setEditedRow] = useState({});
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({
    isOpen: false,
    id: null,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getData = async () => {
    const resp = await getAdminUsersData();
    if (resp.status === 401) {
      removeItem("auth");
      removeItem("access_token");
      removeItem("expiry");
      return navigate("/admin");
    }
    setData(resp.data.data);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const handleEdit = (params) => {
    setIsEditMode(true);
    setEditedRow(params.row);
  };

  const handleUpdate = async () => {
    let resp = await updateListUsers(editedRow);
    if (resp.status === 401) {
      removeItem("auth");
      removeItem("access_token");
      removeItem("expiry");
      return navigate("/admin");
    }
    getData();
    setIsEditMode(false);
    setEditedRow({});
  };

  const handleDelete = async (params) => {
    handleClose();
    const resp = await deleteListUsers(params.row._id);
    if (resp.status === 401) {
      removeItem("auth");
      removeItem("access_token");
      removeItem("expiry");
      return navigate("/admin");
    }
    setData(resp.data.data);
    console.log("Delete clicked for row with ID:", params.row._id);
    getData();
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setEditedRow({});
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setEditedRow((prevRow) => ({ ...prevRow, [name]: value }));
  };
  const subscription = {
    30: "Monthly",
    366: "Yearly",
    0: "None"
  }

  const rows = Array.isArray(data)
    ? data.map((row, index) => ({
        id: index + 1,
        ...row,
        subscription: (row.subscribed_validity)? (subscription[row.subscribed_validity])? subscription[row.subscribed_validity]: row.subscribed_validity :"None",
      }))
    : [];

  const handleCellClick = (params) => {
    setUserInfo({
      isOpen: true,
      id: params.row,
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      flex: 1,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleCellClick(params)}
          >
            {params.row.username}
          </div>
        );
      },
    },
    {
      field: "is_admin",
      headerName: "IsAdmin",
      flex: 1,
      valueGetter: (params) => (params.row.is_admin ? "True" : "False"),
    },
    {
      field: "is_verified",
      headerName: "IsVerified",
      flex: 1,
      valueGetter: (params) =>
        params.row.is_verified ? "Verified" : "Not Verified",
      cellClassName: (params) =>
        params.value === "Verified" ? "cell-verified" : "cell-not-verified",
      renderCell: (params) => (
        <span className={params.cellClassName}>{params.value}</span>
      ),
    },
    {
      field: "adult",
      headerName: "Adult",
      hide: true,
      flex: 1,
    },
    {
      field: "subscription",
      headerName: "Subscription",
      hide: true,
      flex: 1,
    },

    {
      field: "dt",
      headerName: "Date/Time",
      flex: 1,
      valueGetter: (params) => {
        const dtValue = params.row.dt;
        const date = new Date(dtValue);
        return date.toLocaleString();
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEdit(params)}
              size="small"
              sx={{ mx: 1 }}
            >
              <EditIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton size="small" sx={{ mx: 1 }} onClick={handleClickOpen}>
              <DeleteIcon color="secondary" />
            </IconButton>
          </Tooltip>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={() => handleDelete(params)} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ),
    },
  ];

  return (
    <>
      <Container sx={{ display: "flex", height: "80vh" }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={11} sm={8} md={12}>
            <Typography variant="h5" textAlign={"center"} sx={{ my: 5 }}>
              {!userInfo.isOpen ? "Users" : ""}
            </Typography>
            {!isEditMode && !userInfo.isOpen && rows?.length > 0 && (
              <DataGrid
                slots={{ toolbar: GridToolbar }}
                rows={rows}
                columns={columns}
                getRowId={(row) => row._id}
                onEditCellChangeCommitted={handleUpdate}
                editMode="row"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  height: "70vh",
                }}
              />
            )}
            {isEditMode && (
              <div className="overlay">
                <Box className="modal" sx={{ p: 3 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Edit User:
                  </Typography>
                  <Box className="modal-body edit-user">
                    <Stack spacing={2}>
                      <TextField
                        sx={{
                          color: "black",
                        }}
                        label="Username"
                        name="username"
                        value={editedRow.username}
                        onChange={handleFieldChange}
                      />
                      <TextField
                        label="Password"
                        type="password"
                        name="password"
                        value={editedRow.password}
                        onChange={handleFieldChange}
                      />
                      <FormControl>
                        <InputLabel>Is Admin</InputLabel>
                        <Select
                          name="is_admin"
                          value={editedRow.is_admin}
                          onChange={handleFieldChange}
                          label="Is Admin"
                        >
                          <MenuItem value={true}>True</MenuItem>
                          <MenuItem value={false}>False</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </Box>
                  <Box className="modal-footer" sx={{ mt: 2 }}>
                    <Button
                      variant='outlined'
                      onClick={handleCancel}
                      sx={{ mr: 2 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='outlined'
                      onClick={handleUpdate}
                      sx={{ mr: 2 }}
                    >
                      Update
                    </Button>
                  </Box>
                </Box>
              </div>
            )}
            {userInfo.isOpen && (
              <UserDetail userData={userInfo.id} setUserInfo={setUserInfo} />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default AdminUsers;
