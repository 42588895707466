import React from "react";
import { Grid, Button, Box, Stack, TextField, Typography } from "@mui/material";

function UserDetail({ userData, setUserInfo }) {
  return (
    <>
      <Grid item xs={11} sm={8} md={12}>
        <Typography variant="h6" sx={{ mx: 3 }}>
          User Details
        </Typography>
        <div className="overlay">
          <Box className="modal" sx={{ p: 3 }}>
            <Box className="modal-body">
              <Stack spacing={2}>
                <TextField
                  sx={{
                    color: "black",
                  }}
                  label="Username"
                  name="username"
                  value={userData.username}
                  disabled
                />
                <TextField
                  label="Creation Date"
                  name="dt"
                  value={new Date(userData.dt).toLocaleString()}
                  disabled
                />
                <TextField
                  label="Is Adult"
                  name="alult"
                  value={userData.adult}
                  disabled
                />
                <TextField
                  label="Is Admin"
                  name="admin"
                  value={userData.is_admin}
                  disabled
                />
                <TextField
                  label="Email Verified"
                  name="is_verified"
                  value={userData.is_verified}
                  disabled
                />
              </Stack>
            </Box>
          </Box>
        </div>
        {userData.subscribed_dt &&
        userData.subscribed_validity &&
        userData.subscription_id ? (
          <>
            <Typography variant="h6" sx={{ mx: 3 }}>
              Subscriptions Details
            </Typography>
            <div className="overlay">
              <Box className="modal" sx={{ p: 3 }}>
                <Box className="modal-body">
                  <Stack spacing={2}>
                    <TextField
                      label="Subscription Id"
                      name="subscription_id"
                      value={userData.subscription_id}
                      disabled
                    />
                    <TextField
                      sx={{
                        color: "black",
                      }}
                      label="Subscribed At"
                      name="subscribed_dt"
                      value={new Date(userData.subscribed_dt).toLocaleString()}
                      disabled
                    />
                    <TextField
                      label="Subscription Days"
                      name="subscribed_validity"
                      value={userData.subscribed_validity}
                      disabled
                    />
                    <TextField
                      label="Subscription End Date"
                      name="subscribed_end_date"
                      value={new Date(
                        new Date(userData.subscribed_dt).setDate(
                          new Date(userData.subscribed_dt).getDate() + 366
                        )
                      ).toLocaleString()}
                      disabled
                    />
                  </Stack>
                </Box>
              </Box>
            </div>
          </>
        ) : (
          <div style={{ marginBottom: "15px" }}>
            <Typography variant="h6" sx={{ mx: 3 }}>
              Subscriptions Details
            </Typography>
            <Typography sx={{ mx: 3 }}>
              <i>User Does not have any Subscription</i>
            </Typography>
          </div>
        )}
        <Box className="modal-footer" variant="h6" sx={{ mx: 3 }}>
          <Button
            variant='outlined'
            onClick={() => {
              setUserInfo({
                isOpen: false,
                id: null,
              });
            }}
            sx={{ mr: 2 }}
          >
            Back
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default UserDetail;
