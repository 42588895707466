import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Grid, Container } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getAdminlistParticipantsData } from "../service/api";
import { BASE_URL } from "../service/httpclient";
import UserDetail from "../component/UserDetail";
import { removeItem } from "../service/storage";

function AudioCell({ value }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);
  const [currentPlayingRef, setCurrentPlayingRef] = useState(null);

  const handlePlay = async () => {
    setIsPlaying(true);
    setCurrentPlayingRef(audioRef);
    await audioRef.current.play();
  };

  const handlePause = () => {
    setIsPlaying(false);
    setCurrentPlayingRef(null);
    audioRef.current.pause();
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  useEffect(() => {
    // Pause the previous audio if a new one is clicked
    if (currentPlayingRef && currentPlayingRef !== audioRef) {
      currentPlayingRef.current.pause();
      currentPlayingRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, [audioRef, currentPlayingRef]);

  return (
    <>
      <audio
        ref={audioRef}
        onEnded={handlePause}
        onTimeUpdate={handleTimeUpdate}
      >
        <source
          src={`${BASE_URL}/admin/streamAudio/${value}`}
          type="audio/mpeg"
        />
      </audio>
      {isPlaying ? (
        <button onClick={handlePause}>Pause</button>
      ) : (
        <button onClick={handlePlay}>Play </button>
      )}
      <span> : {formatTime(currentTime)}</span>
    </>
  );
}

function AdminRecordings() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [userInfo, setUserInfo] = useState({
    isOpen: false,
    id: null,
  });

  const getData = async () => {
    const resp = await getAdminlistParticipantsData();
    if (resp.status === 401) {
      removeItem("auth");
      removeItem("access_token");
      removeItem("expiry");
      return navigate("/admin");
    }
    setData(resp.data.data);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const rows = data?.map((row, index) => ({
    id: index + 1,
    sx: row.signers.map((m) => { return m.name }),
    files: row.files.map((m) => { return m }) || [],
    ...row,
  }));


  console.log("row::::", rows);
  const handleCellClick = (params) => {
    setUserInfo({
      isOpen: true,
      id: params.row.user_id,
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      hide: true,
      width: "20px",
      flex: 0.5
    },
    {
      field: "appname",
      headerName: "App Name",
      hide: true,
      width: "20px",
      flex: 1
    },
    {
      field: "sx",
      headerName: "Signers",
      hide: true,
      flex: 2,
      renderCell: (params) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleCellClick(params)}
          >

            <ul className="flex">
              {params.value.map((role, index) => (
                <li key={index}>{role}</li>
              ))}
            </ul>


          </div>
        );
      },

    },
    {
      field: "files",
      headerName: "Recordings",
      hide: true,
      flex: 3,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <ul className="flex1">
              {params.value.map((role, index) => (
                <li key={index}>
                  {role.file_info?.originalname}<span>{"  "}</span>
                  <AudioCell value={role?._id} />
                </li>
              ))}
            </ul>
          </div>
        );
      },
    },
    {
      field: "dt",
      headerName: "Date/Time",
      flex: 1,
      valueGetter: (params) => {
        const dtValue = params.row.dt;
        const date = new Date(dtValue);
        return date.toLocaleString();
      },
    },
  ];

  return (
    <>
      <Container sx={{ display: "flex", height: "80vh" }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={11} sm={8} md={12}>
            <Typography variant="h5" textAlign={"center"} sx={{ my: 5 }}>
              {!userInfo.isOpen ? "Envelopes" : ""}
            </Typography>
            {data && !userInfo.isOpen && (
              <DataGrid
                slots={{ toolbar: GridToolbar }}
                rows={rows}
                columns={columns}
                getRowId={(row) => row._id}
                getRowHeight={() => 'auto'}
                sx={{
                  backgroundColor: "white",
                }}
              />
            )}
            {userInfo.isOpen && (
              <UserDetail userData={userInfo.id} setUserInfo={setUserInfo} />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default AdminRecordings;
