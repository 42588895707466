import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import {
  DefaultAppBox,
  DefaultAppContainer,
  PrimaryButton,
  SecondaryButton,
  TopContainer,
} from "../component/common/element";
import CustomInput from "../component/inputfield";
import { login, sendVerificationLink } from "../service/auth";
import { emailValidation } from "../utils/validate";

const Login = () => {
  const navigate = useNavigate();
  const [pageMode, setPageMode] = useState("login");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState("");
  const goToSignup = () => {
    navigate("/signup");
  };
  const goToHome = () => {
    navigate("/mainpage");
  };

  const submit = async () => {
    setErrorMessage("");
    setSucessMessage("");
    let errMessage = "",
      sucssMessage = "";

    if (pageMode === "login") {
      if (emailValidation(username) && password !== "") {
        const response = await login({ username, password });
        console.log("resp", response);
        if (response.status === 200) {
          if (response.data.data.is_verified) {
            goToHome();
          } else {
            console.log("error", response)
            errMessage = "Account Not Verified.";
          }
        } else {
          console.log("error2", response)

          errMessage = response.message;
        }
      } else {
        errMessage = "Email and Password are Mandatory for Login";
      }
    }

    if (pageMode === "forgot-password") {
      if (emailValidation(username)) {
        const response = await sendVerificationLink({ username });

        console.log("response", response);

        if (response.status === 200) {
          sucssMessage = "Password Change Link sent to your Email";
          setUsername("");
        } else {
          errMessage = response.data.message;
        }
      } else {
        errMessage = "Email is Mandatory";
      }
    }

    setErrorMessage(errMessage);
    setSucessMessage(sucssMessage);
  };

  useEffect(() => {
    setUsername("");
    setPassword("");
    setErrorMessage("");
    setSucessMessage("");
  }, [pageMode]);

  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          <DefaultAppBox>
            <Typography
              sx={{ display: "flex", height: "10vh", justifyContent: "center" }}
              variant="h4">
              HEDON XX
            </Typography>
            {pageMode === "login" && (
              <>
                <Typography
                  sx={{ color: "#fff", mb: 3 }}
                  variant="h5"
                  textAlign={"center"}>
                  Login
                </Typography>
                <CustomInput
                  type="text"
                  changeEvent={setUsername}
                  value={username}
                  placeholder="Username/Email"
                />
                <CustomInput
                  type="password"
                  changeEvent={setPassword}
                  value={password}
                  placeholder="Password"
                />
                <FormGroup>
                  <FormControlLabel
                    className="form-label"
                    control={
                      <Checkbox
                        sx={{
                          color: "#fff",
                          "&.Mui-checked": {
                            color: "#fff",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2" sx={{ color: "#fff" }}>
                        Remember Me
                      </Typography>
                    }
                  />
                </FormGroup>
              </>
            )}
            {pageMode === "forgot-password" && (
              <>
                <Typography
                  sx={{ color: "#fff", mb: 3 }}
                  variant="h5"
                  textAlign={"center"}>
                  Forgot Password
                </Typography>
                <CustomInput
                  type="text"
                  changeEvent={setUsername}
                  value={username}
                  placeholder="Username/Email"
                />
              </>
            )}
            {successMessage && (
              <Typography
                textAlign={"center"}
                sx={{
                  mb: 2,
                  fontSize: "14px",
                  borderRadius: "5px",
                  backgroundColor: "#7DE24E77",
                  p: "10px",
                }}
                className="text-white">
                {successMessage}
              </Typography>
            )}
            {errorMessage && (
              <Typography
                textAlign={"center"}
                sx={{
                  mb: 2,
                  fontSize: "14px",
                  borderRadius: "5px",
                  backgroundColor: "#FF78BB77",
                  p: "10px",
                }}
                className="text-white">
                {errorMessage}
              </Typography>
            )}
            <Stack
              sx={{ display: "flex", alignItems: "center", gap: 3, mt: 3 }}>
              <PrimaryButton variant="outlined" onClick={() => submit()}
                size="large">
                {pageMode === "login" ? "Login" : "Verify Email"}
              </PrimaryButton>
              <SecondaryButton
                variant="outlined"
                onClick={() => goToSignup()}
                size="large">
                Create New user
              </SecondaryButton>
              <SecondaryButton
                variant="outlined"
                onClick={() =>
                  setPageMode(
                    pageMode === "login" ? "forgot-password" : "login"
                  )
                }
                size="large">
                {pageMode === "login" ? "Forgot Password" : "Proceed To Login"}
              </SecondaryButton>
            </Stack>
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
};

export default Login;
