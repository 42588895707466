import * as React from "react"
import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"
import MuiTextField from "@mui/material/TextField"
import { Typography } from "@mui/material"

const options = {
  shouldForwardProp: prop => prop !== "borderColor"
}
const outlinedSelectors = [
  "& .MuiOutlinedInput-notchedOutline",
  "&:hover .MuiOutlinedInput-notchedOutline",
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline"
]
const TextField = styled(
  MuiTextField,
  options
)(({ borderColor }) => ({
  "& label.Mui-focused": {
    color: '#fff'
  },
  [outlinedSelectors.join(",")]: {
    borderWidth: 1,
    borderColor,
    color: 'white'
  }
}))

export default function BasicTextFields(props) {
  return (
    <Box
      className="box-wrapper"
      component="form"
      sx={{
        "& > :not(style)": { my: 1 }
      }}
    >
      <TextField type={props.type ? props.type : 'text'} sx={{ color: '#fff' }} value={props.value} onChange={(e) => props.changeEvent(e.target.value)} label={<Typography variant="body2" sx={{ color: '#fff' }}>{props.placeholder}</Typography>} borderColor="white" fullWidth />
    </Box>
  )
}