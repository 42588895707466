import { Checkbox, Stack, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { DefaultAppBox, DefaultAppContainer, PrimaryButton, SecondaryButton, TopContainer } from "../component/common/element";
import CustomInput from "../component/inputfield";
import { register } from "../service/auth";

const SignUp = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [adult, setAdultValue] = useState(false);
  const [pageState, setPageState] = useState("signUpInitiated");
  const [tnd, setTND] = useState(false);

  const goToLogin = () => {
    navigate("/login");
  };

  const handleSubmit = async () => {
    setErrorMessage("");
    let errMessage = "";
    if (username !== "" && password !== "" && password === cPassword) {
      const response = await register({ username, password, adult });
      if (response.status === 200) {
        setPageState("signUpSuccessful");
      } else {
        errMessage = response.data.message;
      }
    } else {
      if (password !== cPassword) errMessage = "Password and Confirm Password should be same";
      else {
        errMessage = "Email and Password are Mandatory for Registration";
      }
    }
    setErrorMessage(errMessage);
  };
  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          {pageState === "signUpInitiated" && (
            <DefaultAppBox my={"40px"}>
              <Typography variant='h4' textAlign={"center"} sx={{ mb: 2 }} className='text-white'>
                Sign Up
              </Typography>
              <CustomInput
                type='email'
                changeEvent={setUsername}
                value={username}
                placeholder='Email'
              />
              <CustomInput
                type='password'
                changeEvent={setPassword}
                value={password}
                placeholder='Password'
              />
              <CustomInput
                type='password'
                changeEvent={setCPassword}
                value={cPassword}
                placeholder='Confirm Password'
              />
              <FormGroup>
                <FormControlLabel
                  className='form-label'
                  control={
                    <Checkbox
                      value={adult}
                      onChange={(e) => setAdultValue(!adult)}
                      sx={{
                        color: "#fff",
                        "&.Mui-checked": {
                          color: "#fff",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography variant='body2' sx={{ color: "#fff" }}>
                      I am above 18 years of age
                    </Typography>
                  }
                />
              </FormGroup>
              <Typography sx={{ mt: 2, borderRadius: 1, border: 1, padding: "4px", color: "#fff" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos doloremque minus
                inventore quae accusamus! Quae ipsa debitis repellendus quod sint
              </Typography>
              <FormGroup>
                <FormControlLabel
                  className='form-label'
                  control={
                    <Checkbox
                      value={tnd}
                      onChange={(e) => setTND(!tnd)}
                      sx={{
                        color: "#fff",
                        "&.Mui-checked": {
                          color: "#fff",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography variant='body2' sx={{ color: "#fff" }}>
                      Agree to the Terms of Service
                    </Typography>
                  }
                />
              </FormGroup>
              {errorMessage && (
                <Typography
                  textAlign={"center"}
                  sx={{
                    mb: 2,
                    fontSize: "14px",
                    borderRadius: "5px",
                    backgroundColor: "rgba(255,120,187,0.6)",
                    p: "4px",
                  }}
                  className='text-white'>
                  {errorMessage}
                </Typography>
              )}
              <Stack sx={{ display: "flex", alignItems: "center", gap: 3, mt: 3 }}>
                <PrimaryButton
                  disabled={!tnd}
                  variant='outlined'
                  onClick={() => handleSubmit()}
                  size='large'>
                  submit{" "}
                </PrimaryButton>
                <SecondaryButton
                  variant='outlined'
                  onClick={() => goToLogin()}
                  size='large'>
                  Already have account
                </SecondaryButton>
              </Stack>
            </DefaultAppBox>
          )}
          {pageState === "signUpSuccessful" && (
            <DefaultAppBox>
              <Typography
                sx={{
                  mb: 2,
                  fontSize: "14px",
                  textAlign: "center",
                  borderRadius: "5px",
                  border: "1px solid #fff",
                  p: "4px",
                }}
                className='text-white'>
                The verification link has been sent to your email. <br />
                Please check it to confirm your new account. <br />
              </Typography>
              <Stack sx={{ display: "flex", alignItems: "center", mt: 3 }}>
                <PrimaryButton
                  variant='outlined'
                  onClick={() => goToLogin()}
                  size='large'>
                  Proceed To Login
                </PrimaryButton>
              </Stack>
            </DefaultAppBox>
          )}
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
};

export default SignUp;
