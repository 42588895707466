import React from "react";
import {  Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DefaultAppBox, DefaultAppContainer, PrimaryButton, TopContainer } from "../component/common/element";

const ErrorPage = () => {
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate("/login");
  };
  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          <DefaultAppBox>
            <Typography
              sx={{ display: "flex", height: "10vh", justifyContent: "center" }}
              variant='h4'>
              Opps! Page not found.
            </Typography>
            <Stack sx={{ display: "flex", alignItems: "center", gap: 4, mt: 3 }}>
              <PrimaryButton
                variant='outlined'
                onClick={() => goToLogin()}
                size='large'>
                Return To Login
              </PrimaryButton>
            </Stack>
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
};

export default ErrorPage;
