import { Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { DefaultAppBox, DefaultAppContainer, PrimaryButton, SecondaryButton, TopContainer } from "../component/common/element";

const EndPage = () => {
  const navigate = useNavigate();
  const goToMainPage = () => {
    navigate("/mainpage");
  };

  const goToHostingWebsite = () => { };

  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          <DefaultAppBox>
            <Typography
              variant='h6'
              gutterBottom
              sx={{ mt: 4, color: "#fff", px: 4, lineHeight: 1.2 }}
              textAlign={"center"}>
              Thank you for your submission
            </Typography>
            <Stack sx={{ display: "flex", alignItems: "center", gap: 3, mt: 4 }}>
              <PrimaryButton
                variant='outlined'
                onClick={() => goToHostingWebsite()}
                size='large'>
                View Recording
              </PrimaryButton>
              <SecondaryButton
                variant='outlined'
                onClick={() => goToMainPage()}
                size='large'>
                Main Page
              </SecondaryButton>
            </Stack>
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
};

export default EndPage;
