import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Container,
  IconButton,
  Tooltip,
  TextField,
  Box,
  Stack,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  getAdminSubscriptionsData,
  deleteListSubscriptions,
  updateListSubscriptions,
} from "../service/api";
import { removeItem } from "../service/storage";

function AdminSubscriptions() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedRow, setEditedRow] = useState({});
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getData = async () => {
    const resp = await getAdminSubscriptionsData();
    if (resp.status === 401) {
      removeItem("auth");
      removeItem("access_token");
      removeItem("expiry");
      return navigate("/admin");
    }
    setData(resp.data.data);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const handleEdit = (params) => {
    setIsEditMode(true);
    setEditedRow(params.row);
  };

  const handleUpdate = async () => {
    let resp = await updateListSubscriptions(editedRow);
    if (resp.status === 401) {
      removeItem("auth");
      removeItem("access_token");
      removeItem("expiry");
      return navigate("/admin");
    }
    getData();
    setIsEditMode(false);
    setEditedRow({});
  };

  const handleDelete = async (params) => {
    handleClose();
    const resp = await deleteListSubscriptions(params.row._id);
    if (resp.status === 401) {
      removeItem("auth");
      removeItem("access_token");
      removeItem("expiry");
      return navigate("/admin");
    }
    setData(resp.data.data);
    console.log("Delete clicked for row with ID:", params.row._id);
    getData();
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setEditedRow({});
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setEditedRow((prevRow) => ({ ...prevRow, [name]: value }));
  };

  const rows = Array.isArray(data)
    ? data.map((row, index) => ({
        id: index + 1,
        ...row,
      }))
    : [];

  const columns = [
    {
      field: "id",
      headerName: "Id",
      hide: true,
      flex: 1
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1
    },
    {
      field: "header",
      headerName: "Header",
      flex: 1
    },

    {
      field: "active",
      headerName: "Active",
      hide: true,
      flex: 1
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1
    },
    {
      field: "client_secret",
      headerName: "Client Secret",
      flex: 1
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 1
    },
    {
      field: "dt",
      headerName: "Date/Time",
      flex: 1,
      valueGetter: (params) => {
        const dtValue = params.row.dt;
        const date = new Date(dtValue);
        return date.toLocaleString();
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEdit(params)}
              size="small"
              sx={{ mx: 1 }}
            >
              <EditIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton size="small" sx={{ mx: 1 }} onClick={handleClickOpen}>
              <DeleteIcon color="secondary" />
            </IconButton>
          </Tooltip>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={() => handleDelete(params)} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ),
    },
  ];

  return (
    <>
      <Container sx={{ display: "flex", height: "80vh" }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={11} sm={8} md={12}>
            <Typography variant="h5" textAlign={"center"} sx={{ my: 5 }}>
              Subscriptions
            </Typography>
            {!isEditMode && rows?.length > 0 && (
              <DataGrid
                slots={{ toolbar: GridToolbar }}
                rows={rows}
                columns={columns}
                getRowId={(row) => row._id}
                onEditCellChangeCommitted={handleUpdate}
                editMode="row"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  height: "70vh",
                }}
              />
            )}
            {isEditMode && (
              <div className="overlay">
                <Box className="modal" sx={{ p: 3 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Edit Subscription:
                  </Typography>
                  <Box className="modal-body edit-subscription">
                    <Stack spacing={2}>
                      <TextField
                        label="Title"
                        name="title"
                        value={editedRow.title}
                        onChange={handleFieldChange}
                      />
                      <TextField
                        label="Header"
                        name="header"
                        value={editedRow.header}
                        onChange={handleFieldChange}
                      />
                      <TextField
                        sx={{
                          color: "black",
                        }}
                        label="Client Secret"
                        name="client_secret"
                        value={editedRow.client_secret}
                        onChange={handleFieldChange}
                      />
                      <TextField
                        label="Duration"
                        type="number"
                        name="duration"
                        value={editedRow.duration}
                        onChange={handleFieldChange}
                      />
                      <TextField
                        label="Amount"
                        type="number"
                        name="amount"
                        value={editedRow.amount}
                        onChange={handleFieldChange}
                      />
                      <FormControl>
                        <InputLabel id="active-input">Active</InputLabel>
                        <Select
                          name="active"
                          labelId="active-input"
                          value={editedRow.active}
                          onChange={handleFieldChange}
                          label="Active"
                        >
                          <MenuItem value={true}>True</MenuItem>
                          <MenuItem value={false}>False</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </Box>
                  <Box className="modal-footer" sx={{ mt: 2 }}>
                    <Button
                      variant='outlined'
                      onClick={handleCancel}
                      sx={{ mr: 2 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='outlined'
                      onClick={handleUpdate}
                      sx={{ mr: 2 }}
                    >
                      Update
                    </Button>
                  </Box>
                </Box>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default AdminSubscriptions;
