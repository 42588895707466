import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DefaultAppBox,
  DefaultAppContainer,
  TopContainer,
} from "../component/common/element";
import { getConsentUrl } from "../service/api";
import { getUser } from "../service/auth";

export default function ConsentSign() {
  const navigate = useNavigate();
  const params = useParams();
  const user = getUser();
  const goToLogin = () => {
    navigate("/login");
  };

  if (!user) {
    goToLogin();
  }
  if (!user.subscription && !params.user_id) {
    setTimeout(() => {
      navigate("/subscription");
    }, 500);
  }

  useEffect(() => {
    (async () => {
      console.log("consent*******");

      const response = await getConsentUrl();
      console.log("consent",response);
      if (response.status === 200) {
        if (response?.data?.redirect_uri)
          window.location.href = response?.data?.redirect_uri;
      }
    })();
  }, []);

  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          <DefaultAppBox>
            <Typography
              variant="h4"
              textAlign={"center"}
              sx={{ fontSize: "28px", my: 3 }}>
              Connecting to Docusign ... Please wait.
            </Typography>
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
}
