import { Box, Button, Stack, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DefaultAppBox,
  DefaultAppContainer,
  PrimaryButton,
  SecondaryButton,
  TopContainer,
} from "../component/common/element";
import { getListOfApplications } from "../service/api";
import { getUser } from "../service/auth";
import { setItem } from "../service/storage";

export default function ListOfApplications() {
  const navigate = useNavigate();
  const params = useParams();
  const user = getUser();
  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const goToLogin = () => {
    navigate("/login");
  };

  if (!user) {
    goToLogin();
  }
  if (!user.subscription && !params.user_id) {
    setTimeout(() => {
      navigate("/subscription");
    }, 500);
  }

  const StyledButton = styled(Button)(({ theme, color = "primary" }) => ({
    ":hover": {
      color: "#fff",
      backgroundColor: "#5bb133",
      borderColor: "#5bb133",
    },
  }));

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      console.log("fetching applications");
      try {
        const response = await getListOfApplications();

        console.log("getListOfApplications ==>", response);
        if (response.status === 200) {
          console.log("response.data.data ==>", response.data.data[0]);
          setApplications(response.data.data[0]);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []); // eslint-disable-line

  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          <DefaultAppBox>
            <Typography
              variant="h5"
              textAlign={"center"}
              fontWeight={"600"}
              sx={{ color: "#fff", mt: 3, mb: 2, fontSize: "22px" }}>
              List of Applications
            </Typography>
            {isLoading && <img
              src="./assets/images/loader.gif"
              className="loader-gif"
              alt="Loading..." />
            }
            {!isLoading && applications?.map((application, applicationIndex) => {
              return (
                <Box
                  key={applicationIndex}
                  sx={{
                    my: 2,
                    borderRadius: 1,
                    padding: "10px",
                    backgroundColor: "#7DE24E77",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <Typography
                    textAlign={"center"}
                    fontWeight={"600"}
                    sx={{ color: "#fff", fontSize: "14px" }}>
                    AppName:{" "}
                    {application.appname}

                  </Typography>
                  {application.status === "completed" && (
                    <StyledButton
                      variant='outlined'
                      sx={{
                        fontWeight: "600",
                        fontSize: "12px",
                        border: 1,
                        borderColor: "#7DE24E",
                        backgroundColor: "#7DE24E",
                        textTransform: "uppercase",
                        borderRadius: "20px",
                      }}
                      className="btn-gray"
                      size="small"
                      onClick={() => {
                        setItem("appname", application.appname);
                        setItem("envelopeId", application.envelopeId);

                        navigate("/recordingsubmit");
                      }}>
                      Proceed to Recording
                    </StyledButton>
                  )}
                </Box>
              );
            })}
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mt: 2,
                width: "100%",
              }}>
              <PrimaryButton
                variant='outlined'
                size="large"
                onClick={() => {
                  navigate("/applications/create");
                }}>
                Create New Application
              </PrimaryButton>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mt: 2,
                width: "100%",
              }}>
              <SecondaryButton
                variant='outlined'
                size="large"
                onClick={() => {
                  navigate("/mainpage");
                }}>
                Go Back
              </SecondaryButton>
            </Stack>
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
}
