import { Box, Container, styled, Button } from "@mui/material";

export const TopContainer = styled(Container)(() => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  };
});

export const DefaultAppContainer = styled(Box)(() => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: "400px",
  };
});

export const DefaultAppBox = styled(Box)(() => {
  return {
    width: "100%",
    margin: "40px 0",
  };
});

export const PageLoader = (props) => {
  return <>{props.isLoading && <div className="page-loader" />}</>;
};

export const PrimaryButton = styled(Button)(({ theme, color = "primary" }) => ({
  mt: 2,
  fontWeight: "600",
  fontSize: "16px",
  color: "#fff",
  borderColor: "#fff",
  backgroundColor: "#7DE24E",
  textTransform: "capitalize",
  borderRadius: "20px",
  width: "100%",
  ":hover": {
    color: "#fff",
    backgroundColor: "#5bb133",
    borderColor: "#5bb133",
  },
}));

export const SecondaryButton = styled(Button)(({ theme, color = "primary" }) => ({
  mt: 2,
  fontWeight: "600",
  fontSize: "16px",
  borderColor: "#fff",
  backgroundColor: "transparent",
  textTransform: "capitalize",
  borderRadius: "20px",
  color: "#fff",
  width: "100%",
  ":hover": {
    color: "#fff",
    backgroundColor: "#1565c0",
    borderColor: "#fff",
  },
}));