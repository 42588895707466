import ScheduleIcon from "@mui/icons-material/Schedule";
import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useNavigate, useParams } from "react-router-dom";
import {
  DefaultAppBox,
  DefaultAppContainer,
  PrimaryButton,
  SecondaryButton,
  TopContainer,
} from "../component/common/element";
import { upload } from "../service/api";
import { getUser } from "../service/auth";
import { getItem, removeItem, setItem } from "../service/storage";
import CustomInput from "../component/inputfield";

export default function RecordingPauseSubmit() {
  const params = useParams();
  const [audioUrl, setAudioUrl] = useState("");
  const navigate = useNavigate();

  console.log("pp:::", params);

  const user = getUser();
  const goToLogin = () => {
    navigate("/login");
  };

  if (!user) {
    goToLogin();
  }
  if (!user.subscription) {
    setTimeout(() => {
      navigate("/subscription");
    }, 500);
  }

  const [mediaBlobUrl, setmediaBlobUrl] = useState(null);
  const [filename, setFilename] = useState("");
  const [recfinish, setRecfinish] = useState(false);
  const [submsg, setSubmsg] = useState("");

  const recorderControls = useAudioRecorder();

  const addAudioElement = (blob) => {
    blob = new File([blob], filename);
    setmediaBlobUrl(blob);
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    const formData = new FormData();
    setRecfinish(true);
    console.log("blob:::", JSON.stringify(blob));
    formData.append("file", blob);
    document.querySelector(".audio-recorder").parentElement.appendChild(audio);
  };
  useEffect(() => {
    removeItem("audio_id");
  }, []);
  const goToSubmission = async () => {
    const formData = new FormData();
    console.log("mediaBlobUrl", mediaBlobUrl);
    formData.append("file", mediaBlobUrl);
    const session = getItem("fileSession") || params.session;
    const envelopeId = getItem("envelopeId")

    formData.append("session", session);
    formData.append("envelopeId", envelopeId)
    const response = await upload(formData, {});

    console.log("upload =>", response);

    if (response.status === 200) {
      const audio_id = response?.data?.data?._id;
      setItem("audio_id", audio_id);
      setAudioUrl(`/audio/${audio_id}`);
      setSubmsg("Recording has been submitted!");
    }
  };

  return (
    <>
      <TopContainer>
        <DefaultAppContainer>
          <DefaultAppBox>
            <Typography variant="h5" fontWeight={"600"} textAlign={"center"}>
              Recording...
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <ScheduleIcon sx={{ fontSize: "120px", color: "white", my: 5 }} />
            </Box>
            <br />
            <Box>
              {



                <AudioRecorder
                  style={{ pointerEvents: "none" }}
                  onRecordingComplete={(blob) => addAudioElement(blob)}
                  recorderControls={recorderControls}
                />

              }
            </Box>

            <br />

            <CustomInput
              changeEvent={(value) => {
                setFilename(value);
              }}
              value={filename}
              placeholder={`File Name`}
            />
            <Stack sx={{ display: "flex", alignItems: "center", mt: 0 }}>

              {submsg === "" &&
                <PrimaryButton
                  disabled={recfinish === false || filename === ''}
                  variant='outlined'
                  onClick={() => goToSubmission()}
                  size="large">
                  Submit Recording
                </PrimaryButton>
              }
              {submsg}
              {audioUrl !== "" && (
                <SecondaryButton
                  variant='outlined'
                  onClick={() => {
                    navigate(audioUrl);
                  }}
                  size="large">
                  Go To Audio
                </SecondaryButton>
              )}
            </Stack>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mt: 2,
                width: "100%",
              }}>
              <SecondaryButton
                variant='outlined'
                size="large"
                onClick={() => {
                  navigate("/applications");
                }}>
                Go To Applications Page
              </SecondaryButton>
            </Stack>
          </DefaultAppBox>
        </DefaultAppContainer>
      </TopContainer>
    </>
  );
}
